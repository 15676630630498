.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-body {
  background-image: url("../public/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}

.intro-body {
  display: block;
  background-image: url("../public/images/VANDY.png");
  height: 60vh;
  background-size: cover;
  /* background-repeat: no-repeat; */
  image-rendering: -webkit-optimize-contrast;
  /* padding-top: 150px;
  padding-bottom: 150px; */
}

.banner {
  margin-bottom: 30px;
  transition: transform 250ms;
  transform-style: preserve-3d;
  text-align: center;
  font-family: Inter, sans-serif;
  color: #ffff;
  font-weight: 600;
  font-size: 6vw;
}

.banner:hover {
  transform: translateY(-10px);
}

.body-paragraph {
  color: #ffff;
  font-weight: 600;
  text-align: center;
  font-size: 1.5vw;
}

.profile {
  background-image: linear-gradient(
      180deg,
      rgba(10, 39, 51, 0.8),
      rgba(10, 39, 51, 0.8)
    ),
    url("../public/images/vandy_rec.jpg");
  background-size: cover;
}
